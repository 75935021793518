body {
  /* height: 100vh !important; */
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 13px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7f9 !important;
  color: #002841 !important;


}

.fw-500{
  font-weight: 450 !important;
  color: #000000 !important;
}

h1,h2,h3,h4,h5,h6{
 
  font-weight: 550 !important;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.bg-show{
 background-color: #e9f6fb !important;
 font-weight: 500 !important;
}


.printf{
  font-size: 13px !important;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.articles {
  font-size: small;
}

.followers {
  font-size: small;
}



.css-ahj2mt-MuiTypography-root {
  font-size: .9rem !important;
}

.app {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  /* flex-direction: column; */
  /* gap: 20px; */
  /* height: 100vh; */
  /* background-image: url('Images/2-min.png'); */
  background-size: cover;
  background-repeat: no-repeat;


}

.ant-picker-input>input::placeholder {
  color: #585959 !important;
}
:where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined {
  
  border-color: #b1b1b1 !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-picker .ant-picker-suffix >* {
  vertical-align: top;
  color: #0381FF !important;
}
/* input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #d27e01;
  border: 1px solid #d27801;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
} */

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 1rem;
  border-radius: 7%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 10%;
  min-width: 350px;
  margin-bottom: 10%;

}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: small;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  margin: 5px;
}

form {
  background: #FFFFFF !important;
  /* padding: 1rem; */
  border-radius: 1rem;
  outline: none;
  text-align: left;

}



/* input[type="button"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #006fba;
  border: 1px solid #006fba;
  color: #ffffff;
  padding: 10px 20px;
}

input[type="button"]:hover {
  background: #ffffff;
  color: #006fba;
} */



.bg-fitek {
  background-color: #006fba;
}

.text-fitek {
  color: #006fba;
}

.tableheader {
  font-weight: 600;
  color: #006fba;
}

.tablediv{
  width: calc(100vw-100px) !important;
  background-color: f6f7f9;
}

.tablecard{
  
  margin-left: 0%;
  margin-right: 0%;
}

.title {
  color: #006fba;
  font-weight: 300;
  padding-bottom: 0%;
  margin-bottom: 0%;
  border-bottom: 1px solid #f4f4f2;

}

.subheader {

  font-weight: 600;
  color: #006fba;
  padding-left: 40px;

}

.reportheading {
  font-weight: 600 !important;
  color: #006fba;

}





.cursorp {
  cursor: pointer;
}


.sidebarstyle {


  height: 95vh;
  transition: 999999999999s;
  animation-name: linear;
}

.iconstyle {


  background-color: #ffffff !important;
  color: #f4f4f2;
}

.pro-sidebar:not(.collapsed) .popper-inner {
  display: none;
}

.pro-sidebar:not(.collapsed) .pro-sidebar-inner {
  width: fit-content;
}


.grid {
  position: relative;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}



.k-column-title {
  white-space: normal !important;
}

.toppings-list {
  list-style: none;
  padding: 0;
}

.toppings-list li {
  margin-bottom: 0.5rem;
}

.toppings-list-item {
  display: flex;
  justify-content: space-between;
}

.borderClass li:last-child {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}



.toppings-list-item label {
  vertical-align: text-bottom;
  margin-left: 0.2rem;
}

.pointer {
  cursor: pointer !important;
}

.k-card-title {
  color: #006fba !important;
}

.sticky-top-clss {
  position: fixed;
  top: 0;
  z-index: 5000;
  min-width: 100%;
  max-height: '30px';
}



.rightHeader {

  justify-content: right;
}
.centerHeader {

  justify-content: center;
}

.k-pager-wrap,
.k-pager {
  display: flex;
}



.tileLayout {

  grid-auto-rows: auto !important;
}

.k-tilelayout-item-header.k-card-header {
  display: none;
}



.k-animation-container {

   z-index: 9999 !important; 

}

.cardStyle {
  padding: 10px;
  min-width: 500px;
  border-radius: 5px;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  border: solid #d5d5d5 1px;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 10;
}
.ant-tabs-nav-list{
  background-color: #f4f4f2;
  z-index: 9999;
}
.\!k-overflow-auto{
  z-index: -5;
}
.cardTopItem {
  padding: 10px;
  min-width: 75px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  border: solid #d5d5d5 1px;
  margin: 5px;
  box-shadow: 1px 2px 5px #666666;
  background: #f4f4f2;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f4f4f2 0%, #f4f2ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f4f4f2 0%, #f4f2ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f4f4f2 0%, #f4f2ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f2', endColorstr='#f4f2ef', GradientType=0);
  /* IE6-9 */

}













/* .k-grid tr:nth-child(odd){
  background-color: #e7e6e6;
}
.k-grid tr:nth-child(even){
  background-color: #ffffff;
} */


/* .h5, h5 {
  font-size: 1rem !important;
} */

.kpicard{
  background-color: #FFFFFF;
  
  
}

.dbcard{
  background-color: #FFFFFF;
  margin-right: 1px;
  margin-right: 1px;

}

.kpiheader{
  background-color: #FFFFFF !important;
  font-size: 12px;
  color: #002841 !important;  
  text-align: start !important;
  font-weight: 500 !important;
  white-space: nowrap;
  overflow: hidden;
  
  text-overflow: ellipsis;
  max-width: fit-content;  
  
}

.kpibody{

  background-color: #FFFFFF;
  color: #006fba !important;
  font-size: 16px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: start !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
 
}

.kpibody:hover{
  text-overflow: clip;
}

.kpiheader:hover{
  text-overflow: clip;
}

.bodybg{
  background-color: #f6f7f9 !important;
}
  

.dbtab{
  min-height: 400px !important;
}

.bgdrawer{
  background-color: #f9f9f9 !important;
}

.tablecard{

  background-color: #FFFFFF;
  height: calc(100vh-500px) !important;



  
}

.accttext{

  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
      font-size: small !important;

}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.k-filter-menu-container{
  /*height:280px !important;*/
  max-height: 250px !important;
  max-width:400px !important;
 
  }
  .k-checkbox-label
  {
   /* word-break: break-all !important;
   max-width: 200px !important; */
   white-space: normal;
  }

  .k-searchbox 
  {
    min-height: 40px !important;
  }

   .customCell{
    white-space: nowrap !important;
    text-decoration: underline;
    }

    

  .fontr{
    font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;

    
  }

.k-label{
  font-size: small !important;
}

#ddrMenu .dropdown-toggle::after {

  content: none !important;
  
  
}
.dropdown-menu {
  --bs-dropdown-padding-x: 0.2rem !important;
  --bs-dropdown-min-width: 15rem !important;

}







.k-grid .k-column-title {
  white-space: normal;
}

.k-grid .k-column-title:hover{
  color: #daa520 !important;
}

.k-grid .k-grid-header .k-filterable:hover{
  color: #daa520 !important;


}

.k-filterable .k-table-th .k-header:hover{
  color: #daa520 !important;



}

.k-link:hover{
  color: #daa520 !important;


}


.k-grid td {
  font-size: 13px !important;
    line-height: 1.5em !important;
 
  border-collapse: collapse;
  font-family: Roboto,  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  border: none !important;
  padding: 2px;

}

.k-grid .k-grouping-row p {
  min-width: 500px;
}

#tileGrid .k-grid-header {
  padding: 0 !important;
}

#tileGrid .k-grid-footer {
  padding: 0 !important;
}

#tileGrid .k-grid-content {
  overflow-y: visible;
}




.k-grid .k-header .k-svg-icon{
  color: #FFFFFF !important;
}

.k-grid .k-grid-header th{
  background-color: #002841 !important;
  font-weight: 450 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  border: 1px solid #ccc !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 3px !important;

}

th.k-header.active .k-grid-column-menu {
  
  background-color: #006fba;
  border-radius: 10px !important;
}

/* Style for the standard rows */ 
.k-grid-content tr.k-alt,
.k-grid-content tr.k-master-row.k-alt {
    background-color: #e9e9e9; /* Light grey for alternate rows */
}

/* Style for the grouped header rows */
.k-grouping-row.k-alt {
    background-color: #e9e9e9; /* Slightly darker shade for grouped header rows */
}

/* Style for the detail rows (sub-rows) within each master row */
.k-grid-content .k-master-row.k-alt + tr.k-detail-row .k-detail-cell {
    background-color: #e9e9e9; /* Same as standard alternate rows */
}

/* Style for the alternate detail rows (sub-rows) within each master row */
.k-grid-content .k-master-row + tr.k-detail-row.k-alt .k-detail-cell {
    background-color: hsl(0, 0%, 91%); /* Same as standard alternate rows */
}
.ant-tabs-nav-operations-hidden{
  display: none !important;
}
.k-icon {
  color: #abaa91;
}
.k-icon .k-i-filter{
  color: black;
}



.tabh{
  margin-top: 0px !important;
  
}

.acctext{
  
  white-space: nowrap;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  font-size: 13px !important;

}

.acctext:hover{
  text-overflow: clip;
  text-size-adjust: auto !important;

}


.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
 
  z-index: 100 !important;
  
  position: relative !important;
  
}

.loginpage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  /* flex-direction: column; */
  /* gap: 20px; */
  /* height: 100vh; */
  background-image: url('Images/mmp_login_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.loading-bar-container{
  width: 100%;
  height: 10px;
  background-color: #ccc;
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.loading-bar{
  width: 0;
  height: 100%;
  background-color: #006fba;
  animation: load 5s linear forwards;
  border-radius: 4px;

}
@keyframes load{
  from{
    width: 0;
  }
  to{
    width: 100%;
  }
}


.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}


.landing-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.landing-title {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: center;
}

.landing-subtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #34495e;
  text-align: center;
}

.landing-paragraph {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #495057;
  text-align: justify;
}

.landing-section-title {
  font-size: 1.75em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #2980b9;
  text-align: left;
}

.landing-list {
  list-style-type: disc;
  padding-left: 20px;
  color: #495057;
  margin-bottom: 20px;
}

.landing-list li {
  margin-bottom: 10px;
}

.rightHeader {

  justify-content: right;
}